import { lazy } from 'react';
import { AppRoute } from './interfaces';
import { AlarmRoutes, CommonRoutes, DocumentRoutes, ProfileRoutes, TicketRoutes } from './util';

const Dashboard = lazy(() => import('pages/auth/Dashboard'));
const Tickets = lazy(() => import('components/tickets/Tickets'));
const TicketPage = lazy(() => import('components/tickets/ticket/TicketPage'));
const NewTicketPage = lazy(() => import('components/tickets/ticket/NewTicketPage'));

const Logout = lazy(() => import('pages/auth/Logout'));
const UserProfile = lazy(() => import('pages/auth/Profile'));

const Documents = lazy(() => import('pages/documents'));
const RoutineMaintenance = lazy(() => import('pages/maintenance/routine'));
const RoutineMaintenanceReports = lazy(() => import('pages/maintenance/reports'));
const SummaryRoutineMaintenanceReports = lazy(() => import('pages/maintenance/summary-reports'));
const PreviewDocument = lazy(() => import('components/documents/preview-document'));
const CommonUsers = lazy(() => import('components/users'));

const NetworkRequests = lazy(() => import('pages/ticketing/network-requests'));
const NewNetworkRequest = lazy(() => import('pages/ticketing/new-network-request'));
const SingleNetworkRequest = lazy(() => import('pages/ticketing/single-network-request'));
const KnowledgeBase = lazy(() => import('pages/knowledge-base/index'));
const SingleKnowledgeBase = lazy(() => import('pages/knowledge-base/single'));
const NewKnowledgeBase = lazy(() => import('pages/knowledge-base/new'));

const CableCrossings = lazy(() => import('pages/cable-crossings'));
const NewCableCrossing = lazy(() => import('pages/cable-crossings/new-cable-crossing'));
const SingleCableCrossing = lazy(() => import('pages/cable-crossings/single-cable-crossing'));

const OSSMap = lazy(() => import('pages/map'));
const Alarms = lazy(() => import('pages/alarms/index'));

//todo: see about router v6 and leveraging its capabilities

const APP_ROUTES: AppRoute[] = [
   { path: '/', element: Dashboard },
   { path: '/logout', element: Logout },
   { path: TicketRoutes.ALL, element: Tickets },
   { path: TicketRoutes.NEW, element: NewTicketPage },
   { path: TicketRoutes.EDIT, element: NewTicketPage },
   { path: TicketRoutes.EDIT_WITH_ID, element: NewTicketPage },
   { path: TicketRoutes.SINGLE_TICKET, element: TicketPage },
   { path: TicketRoutes.CALENDAR, element: Tickets },

   { path: TicketRoutes.ALL_NETWORK_REQUESTS, element: NetworkRequests },
   { path: TicketRoutes.NEW_NETWORK_REQUEST, element: NewNetworkRequest },
   { path: TicketRoutes.EDIT_NETWORK_REQUEST, element: NewNetworkRequest },
   { path: TicketRoutes.SINGLE_NETWORK_REQUEST, element: SingleNetworkRequest },

   { path: TicketRoutes.ALL_CABLE_CROSSINGS, element: CableCrossings },
   { path: TicketRoutes.NEW_CABLE_CROSSING, element: NewCableCrossing },
   { path: TicketRoutes.EDIT_CABLE_CROSSING, element: NewCableCrossing },
   { path: TicketRoutes.SINGLE_CABLE_CROSSING, element: SingleCableCrossing },

   { path: DocumentRoutes.ALL, element: Documents },
   { path: DocumentRoutes.DOCUMENT, element: PreviewDocument },
   { path: ProfileRoutes.ALL, element: UserProfile },
   { path: CommonRoutes.USERS, element: CommonUsers },
   { path: CommonRoutes.ROUTINE_MAINTENANCE, element: RoutineMaintenance },

   { path: CommonRoutes.ROUTINE_MAINTENANCE_REPORTS, element: RoutineMaintenanceReports },
   {
      path: CommonRoutes.SUMMARY_ROUTINE_MAINTENANCE_REPORTS,
      element: SummaryRoutineMaintenanceReports,
   },

   { path: CommonRoutes.MAP, element: OSSMap },
   { path: CommonRoutes.KNOWLEDGE_BASE, element: KnowledgeBase },
   { path: CommonRoutes.NEW_KNOWLEDGE_BASE, element: NewKnowledgeBase },
   { path: CommonRoutes.EDIT_SINGLE_KNOWLEDGE_BASE, element: NewKnowledgeBase },
   { path: CommonRoutes.SINGLE_KNOWLEDGE_BASE, element: SingleKnowledgeBase },
   { path: AlarmRoutes.ALARMS, element: Alarms },
];

export default APP_ROUTES;
