export enum TicketRoutes {
   ALL = '/tickets',
   NEW = '/tickets/new',
   EDIT = '/tickets/edit/:ticket',
   EDIT_WITH_ID = '/tickets/edit/:ticket',
   SINGLE_TICKET = '/tickets/:ticket',

   ALL_NETWORK_REQUESTS = '/network-requests',
   NEW_NETWORK_REQUEST = '/network-requests/new',
   EDIT_NETWORK_REQUEST = '/network-requests/edit/:request',
   SINGLE_NETWORK_REQUEST = '/network-requests/:request',

   ALL_CABLE_CROSSINGS = '/cable-crossings',
   NEW_CABLE_CROSSING = '/cable-crossings/new',
   SINGLE_CABLE_CROSSING = '/cable-crossings/:crossing',
   EDIT_CABLE_CROSSING = '/cable-crossings/edit/:crossing',

   CALENDAR = '/tickets/calendar',
}

export enum AdminRoutes {
   TICKETING = '/admin/ticketing',
   USERS = '/admin/users',
   ROLES = '/admin/roles',
   // SETTINGS = '/admin/settings',
}

export enum DocumentRoutes {
   ALL = '/documents',
   DOCUMENT = '/document',
}

export enum ProfileRoutes {
   ALL = '/profile',
   HOME = '/',
   LOGOUT = '/logout',
}

export enum OpenRoutes {
   MAP = '/map',
   SUMMARY_ROUTINE_MAINTENANCE_REPORTS = '/routine-maintenance/summary-reports',
}

export enum CommonRoutes {
   USERS = '/users',
   ROUTINE_MAINTENANCE = '/routine-maintenance',
   ROUTINE_MAINTENANCE_REPORTS = '/routine-maintenance/reports',
   SUMMARY_ROUTINE_MAINTENANCE_REPORTS = '/routine-maintenance/summary-reports',
   KNOWLEDGE_BASE = '/knowledge-base',
   NEW_KNOWLEDGE_BASE = '/knowledge-base/new',
   SINGLE_KNOWLEDGE_BASE = '/knowledge-base/:knowledgeBase',
   EDIT_SINGLE_KNOWLEDGE_BASE = '/knowledge-base/edit/:knowledgeBase',
   MAP = '/map',
}

export enum AlarmRoutes {
   ALARMS = '/alarms',
}

export const Routes = {
   tickets: Object.values(TicketRoutes),
   admin: [
      AdminRoutes.TICKETING,
      AdminRoutes.USERS,
      // AdminRoutes.SETTINGS,
      CommonRoutes.USERS,
      //todo; have a cleaner implementation for common routes injection here
      CommonRoutes.KNOWLEDGE_BASE,
      CommonRoutes.SINGLE_KNOWLEDGE_BASE,
      CommonRoutes.EDIT_SINGLE_KNOWLEDGE_BASE,

      CommonRoutes.ROUTINE_MAINTENANCE,
      CommonRoutes.ROUTINE_MAINTENANCE_REPORTS,
   ],
   documents: [DocumentRoutes.ALL, DocumentRoutes.DOCUMENT],
   profile: [ProfileRoutes.ALL, ProfileRoutes.HOME],
};

export const ROUTE_PATTERN_SEPARATOR = '/:';
export const RoutesWithPatterns = Object.values(Routes)
   .flat(1)
   .filter((route) => route.includes(ROUTE_PATTERN_SEPARATOR));
